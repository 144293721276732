import API from '../../../hooks/api';
import { ALL } from '../../../utils/Constants';
import { Payout, filters, MultiplePayoutStatusRequestData, PayoutData, PayoutHistoryFilter, PayoutHistoryData } from './ScreenHelper';
import { APP_PAYOUTS_URL, getFullURL, PAYOUT_FINANCING_HISTORY , APP_MULTIPLE_PAYOUT_UPDATE} from '../../../utils/Endpoints';
const { REACT_APP_BASE_URL } = process.env;

export const getPayouts = async (
        data: filters,
        callBack: (success: boolean, data: PayoutData, errorMsg: string | null) => void = () => {}
    ) => {
    const paramsObj = getParamObj(data);
    await API.get(getFullURL(APP_PAYOUTS_URL), {params: paramsObj})
    .then((res) => {
        const data: PayoutData = res.data;
        callBack(true, data, null);
        return res.data;
    }).catch((err) => {
        callBack(false, null, err);
    });
}

export const getCDCHistory = async (
    data: PayoutHistoryFilter,
    callBack: (success: boolean, data: PayoutHistoryData[], errorMsg: string | null) => void = () => {}
) => {
    await API.get(PAYOUT_FINANCING_HISTORY, {params: data})
    .then((res) => {
        const data: PayoutHistoryData[] = res.data.payouts;
        callBack(true, data, null);
        return res.data.payouts;
    }).catch((err) => {
        callBack(false, null, err);
    });
}

export const updatePayoutStatus = async (
    payoutRequest: MultiplePayoutStatusRequestData,
    callBack: (success: boolean, errorMsg: string | null) => void = () => {}
) => {
    await API.put(REACT_APP_BASE_URL + APP_MULTIPLE_PAYOUT_UPDATE, payoutRequest)
        .then((res) => {
            callBack(true, null);
            return res.data;
        }).catch((err) => {
            callBack(false, err);
        });
}

function getParamObj(data: filters) {
    const paramsObj = {
        page: data.page,
        size: data.pageSize ?? 10,
        searchText: data.search,
        payoutPageType: data.payoutPageType,
        includeRejectedLoans: data.includeRejectedLoans,
        loadAllRecords: data.loadAllRecords
    };

    if (data.payoutStatus !== ALL) {
        paramsObj['payoutStatus'] = data.payoutStatus;
    }
    if (data.payoutProvider !== ALL) {
        paramsObj['payoutProvider'] = data.payoutProvider;
    }
    return paramsObj;
}

