import React from "react";
import { useState } from "react";
import Logo from "../../assets/images/logo.svg";
import { Roles } from "../../utils/Constants";
import { hasAnyRole } from "../../utils/Helpers";
import { resetAPI } from "./hook/API";
import { confirmReset } from "./hook/ScreenHelper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Reset: React.FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const onChangeLang = () => {
    const langString = i18n.language === "en" ? "ar" : "en";
    document.body.className = langString === "en" ? "ltr" : "rtl";
    i18n.changeLanguage(langString);
    console.log(i18n.t("inputs.customer"));
  };

  const [uid, setUID] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [error, setError] = useState({
    uid: false,
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [errorMessage, setErrorMessage] = useState({ message: "" });

  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    let checkValidation = true;
    if (!uid || !uid.trim()) {
      setError((res) => ({ ...res, uid: true }));
      checkValidation = false;
    }
    if (!oldPassword || !oldPassword.trim()) {
      setError((res) => ({ ...res, oldPassword: true }));
      checkValidation = false;
    }
    if (!newPassword || !newPassword.trim()) {
      setError((res) => ({ ...res, newPassword: true }));
      checkValidation = false;
    }
    if (!confirmPassword || !confirmPassword.trim()) {
      setError((res) => ({ ...res, confirmPassword: true }));
      checkValidation = false;
    }

    if (checkValidation) {
      if (newPassword.trim() === oldPassword.trim()) {
        setErrorMessage({
          message: t("pages.resetPassword.old&NewPasswordSame"),
        });
        return;
      }

      if (newPassword.trim() !== confirmPassword.trim()) {
        setErrorMessage({ message: t("pages.resetPassword.passwordNotMatch") });
        return;
      }

      resetAPI(
        { email: uid, oldPassword: oldPassword, newPassword: newPassword },
        (success) => {
          if (success) {
            //check whether user has the admin roles are not
            const allowedRoles = Object.values(Roles);
            const hasRole = hasAnyRole(allowedRoles);
            if (!hasRole) {
              setErrorMessage({
                message: t("pages.resetPassword.login.notAuthorized"),
              });
              return;
            }
            confirmReset({
              message: t("pages.resetPassword.changePasswordSuccessfully"),
            });
          } else {
            confirmReset({
              message: t("pages.resetPassword.login.invalidCredetials"),
            });
          }
        }
      );
    }
  };

  return (
    <>
      <section className="login">
        <div className="d-flex justify-content-end align-items-center w-100 px-5 pt-2">
          <button
            disabled={i18n.language === "en" ? true : false}
            className="btn btn-link nav-link mx-3"
            aria-current="page"
            onClick={() => onChangeLang()}
          >
            English
          </button>
          <button
            disabled={i18n.language === "en" ? false : true}
            className="btn btn-link nav-link mx-3"
            aria-current="page"
            onClick={() => onChangeLang()}
          >
            العربية
          </button>
        </div>
        <img src={Logo} alt="" />

        <div className="box_container mt-4">
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              ID
            </label>
            <input
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              onChange={(e) => {
                setUID(e.target.value);
                setError((res) => ({ ...res, uid: false }));
              }}
            />
            {error.uid && (
              <small className="text text-danger my-0 mb-1">
                ID is required
              </small>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="oldPassword" className="form-label">
              Old Password
            </label>
            <input
              type="password"
              className="form-control"
              id="oldPassword"
              onChange={(e) => {
                setOldPassword(e.target.value);
                setError((res) => ({ ...res, oldPassword: false }));
              }}
            />
            {error.oldPassword && (
              <small className="text text-danger my-0 mb-1">
                old password is required
              </small>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="newPassword" className="form-label">
              New Password
            </label>
            <input
              type="password"
              className="form-control"
              id="newPassword"
              onChange={(e) => {
                setNewPassword(e.target.value);
                setError((res) => ({ ...res, newPassword: false }));
              }}
            />
            {error.newPassword && (
              <small className="text text-danger my-0 mb-1">
                new password is required
              </small>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">
              Confirm Password
            </label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setError((res) => ({ ...res, confirmPassword: false }));
              }}
            />
            {error.confirmPassword && (
              <small className="text text-danger my-0 mb-1">
                confirm password is required
              </small>
            )}
          </div>
          {errorMessage.message && (
            <div className="text text-danger">{errorMessage.message}</div>
          )}
          <Link to="/" className="text text-warning">
            {t("pages.resetPassword.loginToTheAccount")}
          </Link>
          <br />
          <button
            type="submit"
            className="btn btn-submit mt-2 submit-button"
            onClick={buttonHandler}
          >
            Submit
          </button>
        </div>
      </section>
    </>
  );
};
