import exportFromJSON from "export-from-json";
import { NavigateFunction, Search } from "react-router-dom";
import { setErrorMsg } from "../../../redux/reducers/settingsSlice";
import { store } from "./../../../redux/store";
import {
  getPEP,
  updatePepData,
  updateDocument,
  donwloadDocument,
  getPepDataHistory,
} from "./API";
import { error } from "console";

export interface filters {
  page?: number;
  size?: number;
  searchText?: string;
  loadAllDocuments: boolean;
  isPep: string;
  pepStatus: string;
}

export interface UpdateDataHook {
  nationalId?: string;
  isPep?: boolean;
  category?: string;
  subCategory?: string;
  status?: string;
  reason?: string;
  supportingDocName?: string;
  loanId?: string;
  financialId?: string;
}

export interface PEPData {
  financialId: string;
  nationalId: string;
  isPep: string;
  category: string;
  subCategory: string;
  status: string;
  reason: string;
  checkedBy: string;
  suppotingDocName: string;
  customerName: string;
  email: string;
  phoneNumber: string;
  modifiedTime: string;
  createdAt: string;
  approvedBy: string;
  isSupportingDocPushed?: string;
  dateOfBirth?: string;
}

export interface PEPDataHistory {
  financialId: string;
}

export interface PEPDataList {
  details: PEPData[];
  totalCount: number;
}

// we calling this function when we need to get all records from database
export const callRequest = async (
  setData: React.Dispatch<React.SetStateAction<PEPDataList>>,
  filters: filters,
  initFilters?: filters
) => {
  setData(null);
  await getPEP(initFilters ?? filters, (_, data) => {
    setData({
      ...data,
      details: data?.details,
      totalCount: data?.totalCount,
    });
  });
};

export const downloadDocumentFun = async (item) => {
  let loanId = item?.financialId;
  let nationalId = item?.nationalId;
  try {
    const data = await donwloadDocument(
      { nationalId, loanId },
      (success, errorMsg) => {
        if (success) {
          console.log("Downloaded successfully");
        } else {
          message("Something went wrong: " + errorMsg, false);
        }
      }
    );
  } catch (err) {
    console.error("Error downloading document:", err);
  }
};

//convert file to base64 encoded
const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        const base64String = (reader.result as string).split(",")[1];
        resolve(base64String);
      } else {
        reject("File reading failed");
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

// Open PEP confirm popup
export const updatePepDataPopup = (
  data,
  fileUpload = true,
  reasonFlag,
  refreshData: () => void
) => {
  if (!fileUpload) return updatePepDataFun(data, refreshData);

  let reason = "",
    supportingDocName,
    fileBase64;
  let blockTitle = "Please Mention Your Reason to Update";

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      fileBase64 = await fileToBase64(file);
    }
  };

  const messageEle = (
    <div className="container">
      <div className="mb-3" style={{ textAlign: "start" }}>
        <label htmlFor="recipient-name" className="col-form-label">
          Reason :
        </label>
        <input
          required
          type="text"
          className="form-control"
          placeholder="Enter reason"
          onChange={(e) => (reason = e.target.value)}
        />
        {reasonFlag && <p className="text-danger">Reason is required</p>}
      </div>
      <div className="mb-3" style={{ textAlign: "start" }}>
        <label htmlFor="recipient-name" className="col-form-label">
          Supporting Document : (Optional)
        </label>
        <input
          required
          type="file"
          className="form-control"
          placeholder="Upload Document"
          accept="application/pdf"
          onChange={handleFileChange}
        />
        {/* {true && <p className="text-danger">document is required</p>} */}
      </div>
    </div>
  );

  store.dispatch(
    setErrorMsg({
      icon: null,
      cancelBtnTitle: "Cancel",
      acceptBtnTitle: "Update",
      modalContentClass: "small",
      acceptBtnAction: async () => {
        if (reason && reason != "") {
          await uploadDocumentFun(
            { ...data, reason, supportingDocName },
            fileBase64,
            refreshData
          );
        } else {
          setTimeout(() => {
            updatePepDataPopup(data, fileUpload, true, refreshData);
          }, 0);
        }
      },
      title: blockTitle,
      message: messageEle,
    })
  );
};

const updatePepDataFun = (data, refreshData: () => void) => {
  updatePepData(data, (success, errorMsg) => {
    if (success) {
      message("Updated Successfully", false);
      refreshData();
    } else {
      message("Something went wrong : " + errorMsg, false);
    }
  });
};

export const uploadDocumentFun = async (
  data,
  document,
  refreshData: () => void
) => {
  let nationalId = data?.nationalId;
  let loanId = data?.loanId;
  if (!document || document == undefined) {
    updatePepDataFun(data, refreshData);
    return;
  }

  try {
    await updateDocument(
      { nationalId, loanId, document },
      (success, errorMsg) => {
        if (success) {
          console.log("Uploaded successfully");
          updatePepDataFun(data, refreshData);
        } else {
          message("Something went wrong: " + errorMsg, false);
        }
      }
    );
  } catch (err) {
    console.error("Error uploading document:", err);
  }
};

const message = (data, reload) => {
  store.dispatch(
    setErrorMsg({
      icon: null,
      closeBtn: false,
      acceptBtnTitle: "OK",
      modalContentClass: "small",
      acceptBtnAction: () => {
        if (reload) window.location.reload();
      },
      title: "Status",
      message: data,
    })
  );
};

// Printing pagination items
export const paginationElemnts = (
  dataArray: PEPData[],
  currentPage: number,
  length: number,
  setFilters: (value: React.SetStateAction<filters>) => void
): JSX.Element[] => {
  let pages: JSX.Element[] = [];
  let totalPage = Math.ceil(length / 10);

  const first = (
    <li key={1} className={"page-item" + (1 === currentPage ? " active" : "")}>
      <button
        className="btn btn-link"
        onClick={(e) => setFilters((res) => ({ ...res, page: 1 }))}
      >
        1
      </button>
    </li>
  );
  const last = (
    <li
      key={totalPage}
      className={"page-item" + (totalPage === currentPage ? " active" : "")}
    >
      <button
        className="btn btn-link"
        onClick={(e) => setFilters((res) => ({ ...res, page: totalPage }))}
      >
        {totalPage}
      </button>
    </li>
  );

  if (currentPage - 1 > 1) pages.push(first);
  for (let i = currentPage - 1; i <= currentPage + 1; i++) {
    if (i > 0 && i <= totalPage) {
      const element = (
        <li
          key={i.toString()}
          className={"page-item" + (i === currentPage ? " active" : "")}
        >
          <button
            className="btn btn-link"
            onClick={(e) => setFilters((res) => ({ ...res, page: i }))}
          >
            {i}
          </button>
        </li>
      );
      pages.push(element);
    }
    if (i === currentPage && dataArray?.length < 10) break;
  }
  if (totalPage - currentPage > 1) pages.push(last);

  return pages;
};

// Open details popup
export const showDetails = (t, PEP: PEPData, navigate: NavigateFunction) => {
  store.dispatch(
    setErrorMsg({
      icon: null,
      title: t("More Details"),
      message: (
        <div className="scroller-area" style={{ maxHeight: 550, padding: 20 }}>
          <div className="row">{printElements(t, PEP).map((item) => item)}</div>
        </div>
      ),
    })
  );
};

const printElements = (t, dataArray: any): JSX.Element[] => {
  const array: JSX.Element[] = [];
  Object.keys(dataArray).forEach((key) => {
    array.push(
      <div className="col-12 col-md-6" key={key}>
        <div className="bordered-box">
          <strong>{t("inputs.payouts." + key).toString()}</strong>
          <span className="ms-2">
            {dataArray[key] === "" ||
            dataArray[key] === false ||
            dataArray[key] === undefined
              ? "-"
              : dataArray[key].toString()}
          </span>
        </div>
      </div>
    );
  });
  return array;
};

export const exportData = async (filters, t) => {
  // var loadAllDocuments = (filters.pepStatus == 'ALL' && filters.isPep == 'ALL');

  await getPEP({ ...filters, loadAllDocuments: true }, (success, data) => {
    if (success && data && data?.details?.length) {
      const fields = {
        financialId: t("inputs.pep.financialId"),
        nationalId: t("inputs.pep.nationalId"),
        isPep: t("inputs.pep.isPep"),
        category: t("inputs.pep.category"),
        subCategory: t("inputs.pep.subCategory"),
        status: t("inputs.pep.status"),
        reason: t("inputs.pep.reason"),
        checkedBy: t("inputs.pep.checkedBy"),
        suppotingDocName: t("inputs.pep.suppotingDocName"),
        customerName: t("inputs.pep.customerName"),
        email: t("inputs.pep.email"),
        phoneNumber: t("inputs.pep.phoneNumber"),
        approvedBy: t("inputs.pep.approvedBy"),
        createdAt: t("inputs.pep.createdAt"),
        modifiedTime: t("inputs.pep.modifiedTime"),
      };
      const newData = [];
      data?.details?.map((item: PEPData) => {
        return newData.push({
          financialId: item.financialId ?? "",
          nationalId: item.nationalId ?? "",
          isPep: item.isPep ?? "",
          category: item.category ?? "",
          subCategory: item.subCategory ?? "",
          status: item.status ?? "",
          reason: item.reason ?? "",
          checkedBy: item.checkedBy ?? "",
          suppotingDocName: item.suppotingDocName ?? "",
          customerName: item.customerName ?? "",
          email: item.email ?? "",
          phoneNumber: item.phoneNumber ?? "",
          approvedBy: item.approvedBy ?? "",
          createdAt: item.createdAt ?? "",
          modifiedTime: item.modifiedTime ?? "",
        });
      });

      const fileName = "PEP_Data -";
      const exportType = exportFromJSON.types.csv;
      exportFromJSON({
        data: newData,
        fileName,
        exportType,
        fields,
        withBOM: true,
      });
    }
  });
};

export const exportLogData = async (filters, t) => {
  await getPepDataHistory(
    { financialId: filters.financialId },
    (success, data) => {
      if (!data?.details || data?.details.length == 0)
        return message("No history found", false);
      if (success && data) {
        const fields = {
          financialId: t("inputs.pep.financialId"),
          nationalId: t("inputs.pep.nationalId"),
          isPep: t("inputs.pep.isPep"),
          category: t("inputs.pep.category"),
          subCategory: t("inputs.pep.subCategory"),
          status: t("inputs.pep.status"),
          reason: t("inputs.pep.reason"),
          checkedBy: t("inputs.pep.checkedBy"),
          suppotingDocName: t("inputs.pep.suppotingDocName"),
          customerName: t("inputs.pep.customerName"),
          email: t("inputs.pep.email"),
          phoneNumber: t("inputs.pep.phoneNumber"),
          modifiedTime: t("inputs.pep.modifiedTime"),
          approvedBy: t("inputs.pep.approvedBy"),
        };
        const newData = [];
        data?.details?.map((item: PEPData) => {
          return newData.push({
            financialId: item.financialId ?? "",
            nationalId: item.nationalId ?? "",
            isPep: item.isPep ?? "",
            category: item.category ?? "",
            subCategory: item.subCategory ?? "",
            status: item.status ?? "",
            reason: item.reason ?? "",
            checkedBy: item.checkedBy ?? "",
            suppotingDocName: item.suppotingDocName ?? "",
            customerName: item.customerName ?? "",
            email: item.email ?? "",
            phoneNumber: item.phoneNumber ?? "",
            modifiedTime: item.modifiedTime ?? "",
            approvedBy: item.approvedBy ?? "",
          });
        });

        const fileName = "PEP_Data -";
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({
          data: newData,
          fileName,
          exportType,
          fields,
          withBOM: true,
        });
      }
    }
  );
};
