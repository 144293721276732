import { localStorageHelper } from './localStorageHelper';
import { store } from './../redux/store';
import axios from "axios";
import { setErrorMsg, setLoadingSpinner } from '../redux/reducers/settingsSlice';
import { PROFILE_KEY, SESSOIN_EXPIRY_KEY, supportEmail } from '../utils/Constants';
import moment from 'moment';

const { REACT_APP_BASE_URL } = process.env;

const API = axios.create({
    baseURL: REACT_APP_BASE_URL,
});

API.interceptors.request.use(async (req) => {    
    store.dispatch(setLoadingSpinner(true))
    await new Promise(resolve => setTimeout(resolve, 500))

    if (typeof localStorageHelper.load("profile") !== 'undefined' ) {
        if (typeof localStorageHelper.load("profile").access_token !== 'undefined' ) {
            req.headers = {
                'Authorization': `Bearer ${
                    localStorageHelper.load("profile").access_token
                }`
            };
        }
    }
    return req;
  });
  
API.interceptors.response.use((response) => {
    store.dispatch(setLoadingSpinner(false))
    console.log("res", response);
    return response;
}, (error) => {
    store.dispatch(setLoadingSpinner(false));
    if (error.response.status === 401) {
        logout();
    } else {
        if (isSessionExpired()) {
            logout();
        } else {
            store.dispatch(setErrorMsg({
                title: "Something went wrong",
                message: `Please exit or contact us on ${supportEmail}`
            }))
        }
    }

    if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
    }

    return Promise.reject(error.message);
});

function isSessionExpired() {
    let expiryTime = localStorageHelper.load(SESSOIN_EXPIRY_KEY);
    expiryTime = moment(expiryTime);
    let currentTime = moment();
    return expiryTime.isBefore(currentTime);
}

/**
 * Logs out the user.
 */
function logout(){
    localStorageHelper.remove(PROFILE_KEY);
    window.location.reload();
}
  
/**
 * Executes a get request.
 * @param url - The url to be appended to the base url.
 * @returns 
 */
export const executeGetRequest = async (url: string) => {
    try {
        const response = await API.get(getFullURL(url))
        let { data } = await response
        return data
    } catch (error) {
        console.log(error)
    }
}

/**
 * 
 * @param url - The url to be appended to the base url.
 * @returns - The full url.
 */
export function getFullURL(url: string) {
    return REACT_APP_BASE_URL + url;
}

export default API