/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Search from "../../assets/images/icon-search.svg";
import Arrow from "../../assets/images/pagination_arrow.svg";
import { PEPStatus } from "../../utils/Constants";
import { store } from "./../../redux/store";
import "bootstrap-icons/font/bootstrap-icons.css";
import {
  callRequest,
  PEPData,
  filters,
  paginationElemnts,
  updatePepDataPopup,
  exportData,
  PEPDataList,
  UpdateDataHook,
  downloadDocumentFun,
  exportLogData,
} from "./hook/ScreenHelper";
import { useAppSelector } from "../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { Roles } from "../../utils/Constants";
import { hasRole } from "../../utils/Helpers";
import { TableRowPlaceholder } from "../../components/TableRowPlaceholder/TableRowPlaceholder";
import Moment from "react-moment";
import { setErrorMsg } from "../../redux/reducers/settingsSlice";

export const PEP: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { loading } = useAppSelector((state) => state.settings);
  const [data, setData] = useState<PEPDataList>(null);
  const [timer, setTimer] = useState(null);
  const [searchText, setSearchText] = useState<string>("");
  const [updateHook, setUpdateHook] = useState<UpdateDataHook>({
    nationalId: "",
    loanId: "",
    financialId: "",
  });

  const currentLanguage = i18n.language;
  const [filters, setFilters] = useState<filters>({
    page: 1,
    size: 10,
    searchText: "",
    loadAllDocuments: false,
    pepStatus: "ALL",
    isPep: "ALL",
  });

  const pepCategories = [
    {
      id: 1,
      nameEn: "Family of Royal Highness",
      nameAr: "اﻟﻣﻠﻛﻲ اﻟﺳﻣو اﺻﺣﺎب",
    },
    {
      id: 2,
      nameEn: "Minister",
      nameAr: "وزﯾر",
    },
    {
      id: 3,
      nameEn: "Deputy Minister",
      nameAr: "وزﯾر وﻛﯾل",
    },
    {
      id: 4,
      nameEn: "Personnel Military Senior",
      nameAr: "ﻛﺑﺎر اﻟﻌﺳﻛرﯾﯾن - ﻟواء - ﻓرﯾق - ﻓرﯾق أول",
    },
    {
      id: 5,
      nameEn: "Judge",
      nameAr: "ﻗﺎﺿﻲ",
    },
    {
      id: 6,
      nameEn: "Diplomat, Ambassador",
      nameAr: "ﺳﻔﯾر ، دﺑﻠوﻣﺎﺳﻲ",
    },
    {
      id: 7,
      nameEn: "Head of State and Government",
      nameAr: "واﻟﺣﻛوﻣﺎت اﻟدول رؤﺳﺎء",
    },
    {
      id: 8,
      nameEn: "CEO OR Governor of State-Owned Company OR Institution",
      nameAr: "واﻟﻣؤﺳﺳﺎت اﻟﻣﻣﻠوﻛﺔ ﻟﻠدوﻟﺔاﻟﺷرﻛﺎت وﻣﺣﺎﻓظﯾن اﻟﺗﻧﻔﯾذﯾﯾن اﻟرؤﺳﺎء",
    },
    {
      id: 9,
      nameEn: "Other",
      nameAr: "أﺧرى",
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    callRequest(setData, filters);
    console.log("data", data);
  }, [filters]);

  useEffect(() => {
    if (updateHook.nationalId && updateHook.nationalId !== "") {
      let upload =
        updateHook.hasOwnProperty("categoryId") ||
        updateHook.hasOwnProperty("subCategory");
      updatePepDataPopup(updateHook, !upload, false, () =>
        callRequest(setData, filters)
      );
      setUpdateHook({ nationalId: "", loanId: "", financialId: "" });
    }
  }, [updateHook]);

  /**
   * Returns true if the buttons should be shown for the item
   * @param item - AML item
   * @returns
   */

  const searchInputChanged = (value) => {
    setSearchText(value);
    clearTimeout(timer);
    const newTimer = setTimeout(
      () => setFilters({ ...filters, searchText: value }),
      500
    );
    setTimer(newTimer);
  };

  const changePepStatus = (data) => {
    setFilters({ ...filters, pepStatus: data, page: 1 });
  };

  const changeIsPep = (data) => {
    setFilters({ ...filters, isPep: data, page: 1 });
  };

  const updateData = (key, value, item) => {
    setUpdateHook((res) => ({
      ...res,
      [key]: value,
      nationalId: item?.nationalId,
      loanId: item?.financialId,
      financialId: item?.financialId,
    }));
  };

  const changeSubCategory = (data, error = false) => {
    let subCategory = "";

    let blockTitle = "Enter Your Sub Category";
    const messageEle = (
      <div className="mb-3">
        <label className="form-label">Sub Category</label>
        <input
          type="email"
          onChange={(e) => (subCategory = e.target.value)}
          className="form-control"
          aria-describedby="emailHelp"
        />
        {error && <p className="text-danger">field is required</p>}
      </div>
    );

    store.dispatch(
      setErrorMsg({
        icon: null,
        cancelBtnTitle: "Cancel",
        acceptBtnTitle: "Update",
        modalContentClass: "small",
        acceptBtnAction: () => {
          if (subCategory && subCategory != "") {
            setUpdateHook((res) => ({
              ...res,
              subCategory,
              nationalId: data?.nationalId,
              loanId: data?.financialId,
              financialId: data?.financialId,
            }));
          } else {
            setTimeout(() => {
              changeSubCategory(data, true);
            }, 100);
          }
        },
        title: blockTitle,
        message: messageEle,
      })
    );
  };

  const validateText = (data) => {
    var value = "";
    data
      ? typeof data === "boolean"
        ? (value = data ? "YES" : "NO")
        : (value = data)
      : (value = "NA");

    return <p className={value !== "NA" && "text-primary"}>{value}</p>;
  };

  const getCurrentStatusList = (item) => {
    let statusList = hasRole(Roles.compliance_manager)
      ? [
          PEPStatus.APPROVED,
          PEPStatus.REJECTED,
          PEPStatus.PENDING,
          PEPStatus.CHECKED,
        ]
      : [PEPStatus.PENDING, PEPStatus.CHECKED];

    if (item === PEPStatus.CHECKED)
      statusList = statusList.filter((res) => res !== PEPStatus.PENDING);
    return statusList.filter((res) => res !== item);
  };

  const isFinalStatus = (item) => {
    return (
      getCurrentStatusList(item?.status).length !== 0 &&
      (item?.status === PEPStatus.PENDING || item?.status === PEPStatus.CHECKED)
    );
  };

  const permission = (item) =>
    hasRole(Roles.compliance) && item?.isPep == "true";

  const checkIsPep = (item) => {
    if (item === "true") return ["false"];
    return ["true"];
  };

  const getStatusColor = (status) => {
    switch (status) {
      case PEPStatus.APPROVED:
        return "btn py-1 rounded-pill mb-0 btn-success";
      case PEPStatus.REJECTED:
        return "btn py-1 rounded-pill mb-0 btn-danger";
      case PEPStatus.PENDING:
        return "btn py-1 rounded-pill mb-0 btn-warning";
      case PEPStatus.CHECKED:
        return "btn py-1 rounded-pill mb-0 btn-primary";
      default:
        return "btn py-1 rounded-pill mb-0 btn-secondary";
    }
  };

  return (
    <>
      <div className="box_container">
        <div className="row g-3">
          <div className="col-md-12">
            <div className="row table-header justify-content-end">
              <div className="dropdown margin-end col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                <button
                  className="btn bg-gradient-dark mb-0 dropdown-toggle w-100"
                  data-bs-toggle="dropdown"
                  title="Payoutstatus"
                >
                  {t("inputs.pep.isPep")} (
                  {t("inputs.pep.statusData." + filters.isPep)})
                </button>
                <ul className="dropdown-menu dropdown-right-align">
                  <li key={PEPStatus.ALL}>
                    <a
                      className="dropdown-item"
                      onClick={() => changeIsPep(PEPStatus.ALL)}
                    >
                      {t("inputs.pep.statusData.ALL")}
                    </a>
                  </li>
                  <li key={PEPStatus.YES}>
                    <a
                      className="dropdown-item"
                      onClick={() => changeIsPep(PEPStatus.YES)}
                    >
                      {t("inputs.pep.statusData.TRUE")}
                    </a>
                  </li>
                  <li key={PEPStatus.NO}>
                    <a
                      className="dropdown-item"
                      onClick={() => changeIsPep(PEPStatus.NO)}
                    >
                      {t("inputs.pep.statusData.FALSE")}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="dropdown margin-end col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                <button
                  className="btn bg-gradient-dark mb-0 dropdown-toggle w-100"
                  data-bs-toggle="dropdown"
                  title=""
                >
                  {t("inputs.pep.status")} (
                  {t("inputs.pep.statusData." + filters.pepStatus)})
                </button>
                <ul className="dropdown-menu dropdown-right-align">
                  <li key={PEPStatus.ALL}>
                    <a
                      className="dropdown-item"
                      onClick={() => changePepStatus(PEPStatus.ALL)}
                    >
                      {t("inputs.pep.statusData.ALL")}
                    </a>
                  </li>
                  <li key={PEPStatus.APPROVED}>
                    <a
                      className="dropdown-item"
                      onClick={() => changePepStatus(PEPStatus.APPROVED)}
                    >
                      {t("inputs.pep.statusData.APPROVED")}
                    </a>
                  </li>
                  <li key={PEPStatus.REJECTED}>
                    <a
                      className="dropdown-item"
                      onClick={() => changePepStatus(PEPStatus.REJECTED)}
                    >
                      {t("inputs.pep.statusData.REJECTED")}
                    </a>
                  </li>
                  <li key={PEPStatus.PENDING}>
                    <a
                      className="dropdown-item"
                      onClick={() => changePepStatus(PEPStatus.PENDING)}
                    >
                      {t("inputs.pep.statusData.PENDING")}
                    </a>
                  </li>
                  <li key={PEPStatus.CHECKED}>
                    <a
                      className="dropdown-item"
                      onClick={() => changePepStatus(PEPStatus.CHECKED)}
                    >
                      {t("inputs.pep.statusData.CHECKED")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row table-header">
              <div className="col-sm-12 col-md-11 mb-2">
                <div className="input-group search-input align-items-center">
                  <span className="input-group-text">
                    <img alt="" src={Search} />
                  </span>
                  <input
                    type="number"
                    className="form-select"
                    value={searchText}
                    placeholder={t("inputs.pep.search_input_placeholder")}
                    onChange={(e) => searchInputChanged(e.target.value)}
                  />
                  {filters.searchText && (
                    <button
                      type="button"
                      className="btn-close ps mx-3"
                      aria-label="Close"
                      onClick={() => searchInputChanged("")}
                    ></button>
                  )}
                </div>
              </div>

              <div className="col-sm-12 col-md-1 mb-2">
                <div
                  className="d-flex align-items-center justify-content-end"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="First group"
                  >
                    {/* <button
                      className="btn bg-gradient-dark d-flex-center"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample"
                      aria-controls="offcanvasExample"
                      data-bs-placement="top"
                      title="Filter"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width="19"
                        height="19"
                      >
                        <path d="M3 4c0-.55.45-1 1-1h16c.55 0 1 .45 1 1 0 .22-.07.42-.2.58L14 13.51v6.75c0 .47-.35.9-.82.98-.58.11-1.15-.29-1.15-.89v-6.84L3.2 4.58A.996.996 0 013 4z" />
                      </svg>
                    </button> */}
                  </div>

                  <div
                    className="btn-group"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      className="btn bg-gradient-dark d-flex-center"
                      onClick={() => exportData(filters, t)}
                      type="button"
                      data-bs-toggle="offcanvas"
                      aria-controls="offcanvasExample"
                      data-bs-placement="top"
                      title="Download"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        fill="currentColor"
                        className="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row table-header">
              <div className="col-12 col-md-12 d-flex container-fluid align-items-center">
                <div className="input-group search-input align-items-center">
                  <span className="input-group-text">
                    <img alt="search" src={Search} />
                  </span>
                  <input
                    type="number"
                    className="form-select"
                    value={searchText}
                    placeholder={t("inputs.pep.search_input_placeholder")}
                    onChange={(e) => searchInputChanged(e.target.value)}
                  />
                  {filters.searchText && (
                    <button
                      type="button"
                      className="btn-close ps mx-3"
                      aria-label="Close"
                      onClick={() => searchInputChanged("")}
                    ></button>
                  )}
                </div>

                <div className="dropdown ms-2 margin-end">
                  <button
                    className="btn bg-gradient-dark mb-0 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    title="Payoutstatus"
                  >
                    {t("inputs.pep.isPep")} (
                    {t("inputs.pep.statusData." + filters.isPep)})
                  </button>
                  <ul className="dropdown-menu dropdown-right-align">
                    <li key={PEPStatus.ALL}>
                      <a
                        className="dropdown-item"
                        onClick={() => changeIsPep(PEPStatus.ALL)}
                      >
                        {t("inputs.pep.statusData.ALL")}
                      </a>
                    </li>
                    <li key={PEPStatus.YES}>
                      <a
                        className="dropdown-item"
                        onClick={() => changeIsPep(PEPStatus.YES)}
                      >
                        {t("inputs.pep.statusData.TRUE")}
                      </a>
                    </li>
                    <li key={PEPStatus.NO}>
                      <a
                        className="dropdown-item"
                        onClick={() => changeIsPep(PEPStatus.NO)}
                      >
                        {t("inputs.pep.statusData.FALSE")}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="dropdown ms-2 margin-end">
                  <button
                    className="btn bg-gradient-dark mb-0 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    title=""
                  >
                    {t("inputs.pep.status")} (
                    {t("inputs.pep.statusData." + filters.pepStatus)})
                  </button>
                  <ul className="dropdown-menu dropdown-right-align">
                    <li key={PEPStatus.ALL}>
                      <a
                        className="dropdown-item"
                        onClick={() => changePepStatus(PEPStatus.ALL)}
                      >
                        {t("inputs.pep.statusData.ALL")}
                      </a>
                    </li>
                    <li key={PEPStatus.APPROVED}>
                      <a
                        className="dropdown-item"
                        onClick={() => changePepStatus(PEPStatus.APPROVED)}
                      >
                        {t("inputs.pep.statusData.APPROVED")}
                      </a>
                    </li>
                    <li key={PEPStatus.REJECTED}>
                      <a
                        className="dropdown-item"
                        onClick={() => changePepStatus(PEPStatus.REJECTED)}
                      >
                        {t("inputs.pep.statusData.REJECTED")}
                      </a>
                    </li>
                    <li key={PEPStatus.PENDING}>
                      <a
                        className="dropdown-item"
                        onClick={() => changePepStatus(PEPStatus.PENDING)}
                      >
                        {t("inputs.pep.statusData.PENDING")}
                      </a>
                    </li>
                    <li key={PEPStatus.CHECKED}>
                      <a
                        className="dropdown-item"
                        onClick={() => changePepStatus(PEPStatus.CHECKED)}
                      >
                        {t("inputs.pep.statusData.CHECKED")}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="ms-2 d-flex">
                  <button
                    className="btn m-0 bg-gradient-dark text-white ms-1"
                    onClick={() => exportData(filters, t)}
                    type="button"
                    data-bs-toggle="offcanvas"
                    aria-controls="offcanvasExample"
                    data-bs-placement="top"
                    title="Download"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div> */}
          </div>

          <div className="col-md-12 ">
            <div className="table-responsive" style={{ minHeight: "25rem" }}>
              <table className="table table-hover">
                {!loading && (
                  <thead>
                    <tr>
                      <th className="px-2" scope="col">
                        {t("inputs.pep.createdAt")}
                      </th>
                      <th className="px-2" scope="col">
                        {t("inputs.pep.customerName")}
                      </th>
                      <th className="px-2" scope="col">
                        {t("inputs.pep.financialId")}
                      </th>
                      <th className="px-2" scope="col">
                        {t("inputs.pep.email")}
                      </th>
                      <th className="px-2" scope="col">
                        {t("inputs.pep.phoneNumber")}
                      </th>
                      <th className="px-2" scope="col">
                        {t("inputs.pep.dob")}
                      </th>
                      <th
                        className="pe-5"
                        style={{ minWidth: 120 }}
                        scope="col"
                      >
                        {t("inputs.pep.isPep")}
                      </th>
                      <th
                        className="pe-5"
                        style={{ minWidth: 150 }}
                        scope="col"
                      >
                        {t("inputs.pep.category")}
                      </th>
                      <th
                        className="pe-5"
                        style={{ minWidth: 150 }}
                        scope="col"
                      >
                        {t("inputs.pep.subCategory")}
                      </th>
                      <th
                        className="pe-5"
                        style={{ minWidth: 150 }}
                        scope="col"
                      >
                        {t("inputs.pep.status")}
                      </th>
                      <th className="px-2" scope="col">
                        {t("inputs.pep.reason")}
                      </th>
                      <th className="px-2" scope="col">
                        {t("inputs.pep.suppotingDocName")}
                      </th>
                      <th className="px-2" scope="col">
                        {t("inputs.pep.checkedBy")}
                      </th>
                      <th className="px-2" scope="col">
                        {t("inputs.pep.approvedBy")}
                      </th>
                      <th className="px-2" scope="col"></th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {loading && <TableRowPlaceholder />}
                  {data?.details?.map((item, key) => (
                    <tr key={key}>
                      <td style={{ minWidth: 140 }}>
                        <Moment format="YYYY-MM-DD HH:mm:ss" tz="Asia/Riyadh">
                          {item?.createdAt}
                        </Moment>
                      </td>
                      <td>
                        {item?.customerName} <br />{" "}
                        <span className="text-secondary">
                          {item?.nationalId}
                        </span>
                      </td>
                      <td>{item?.financialId}</td>
                      <td>{item?.email}</td>
                      <td>{item?.phoneNumber}</td>
                      <td>{item?.dateOfBirth}</td>
                      <td>
                        <div className="d-flex justify-content-between align-items-center pe-5">
                          <div>
                            {item?.isPep == "true" ? (
                              <span className="text-info">YES</span>
                            ) : (
                              <span className="text-danger">NO</span>
                            )}
                          </div>
                          {hasRole(Roles.compliance) &&
                            item?.isPep === "true" && (
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Button group with nested dropdown"
                              >
                                <div className="btn-group" role="group">
                                  <button
                                    type="button"
                                    className="btn btn-light rounded mb-0 px-1 py-0 mx-2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-caret-down-square-fill"></i>
                                  </button>
                                  <ul className="aml dropdown-menu shadow-lg">
                                    {checkIsPep(item.isPep).map((res) => (
                                      <li>
                                        <button
                                          onClick={() =>
                                            updateData("isPep", res, item)
                                          }
                                          className="dropdown-item text-primary fw-bold"
                                        >
                                          {res == "true" ? "YES" : "NO"}
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            )}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-between align-items-center pe-5">
                          <div>
                            {permission(item) ? (
                              item?.category
                            ) : (
                              <div className="fs-6 text-secondary">NA</div>
                            )}
                          </div>
                          {permission(item) && (
                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Button group with nested dropdown"
                            >
                              <div className="btn-group" role="group">
                                <button
                                  type="button"
                                  className="btn btn-light rounded mb-0 px-1 py-0 mx-2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="bi bi-caret-down-square-fill"></i>
                                </button>
                                <ul className="aml dropdown-menu shadow-lg">
                                  {pepCategories.map(
                                    (res) =>
                                      res.nameEn != item?.category && (
                                        <li>
                                          <button
                                            onClick={() =>
                                              updateData(
                                                "categoryId",
                                                res.id,
                                                item
                                              )
                                            }
                                            className="dropdown-item text-primary fw-bold"
                                          >
                                            {res.nameEn}
                                          </button>
                                        </li>
                                      )
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-between align-items-center pe-5">
                          <div>
                            {permission(item) ? (
                              item?.subCategory
                            ) : (
                              <div className="fs-6 text-secondary">NA</div>
                            )}
                          </div>
                          <div>
                            {permission(item) && (
                              <button
                                type="button"
                                className="btn btn-light rounded mb-0 px-1 py-0 mx-2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={() => changeSubCategory(item, false)}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-between align-items-center pe-5">
                          <div
                            style={{ width: 120, fontSize: "small" }}
                            className={`${getStatusColor(item?.status)}`}
                          >
                            {item?.status}
                          </div>
                          {isFinalStatus(item) && (
                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Button group with nested dropdown"
                            >
                              <div className="btn-group" role="group">
                                <button
                                  type="button"
                                  className="btn btn-light rounded mb-0 px-1 py-0 mx-2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="bi bi-caret-down-square-fill"></i>
                                </button>
                                <ul className="aml dropdown-menu shadow-lg">
                                  {getCurrentStatusList(item?.status).map(
                                    (res) => (
                                      <li>
                                        <button
                                          onClick={() =>
                                            updateData("status", res, item)
                                          }
                                          className="dropdown-item text-primary fw-bold"
                                        >
                                          {t("inputs.pep.statusData." + res)}
                                        </button>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        {item?.isPep === "true" ? (
                          item?.reason
                        ) : (
                          <div className="fs-6 text-secondary">NA</div>
                        )}
                      </td>
                      <td>
                        {item?.isSupportingDocPushed ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => downloadDocumentFun(item)}
                            className="text-warning"
                          >
                            Click to open
                          </span>
                        ) : (
                          <div className="d-flex justify-content-between align-items-center pe-5">
                            <div className="fs-6 text-secondary">NA</div>
                            {item.isPep == "true" && (
                              <button
                                type="button"
                                className="btn btn-light rounded mb-0 px-1 py-0 mx-2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={() =>
                                  setUpdateHook({
                                    nationalId: item?.nationalId,
                                    loanId: item?.financialId,
                                    financialId: item?.financialId,
                                  })
                                }
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            )}
                          </div>
                        )}
                      </td>
                      <td>{item?.checkedBy}</td>
                      <td>{item?.approvedBy}</td>
                      <td>
                        <button
                          onClick={() => exportLogData(item, t)}
                          className="btn btn-primary mb-0"
                        >
                          History <i className="bi bi-download"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!loading && data?.details?.length === 0 && (
                <div className="text-center text-muted">No data available</div>
              )}
            </div>
            <div className="col-md-12 ">
              <div className="table-footer">
                <div className="results-count">
                  <p></p>
                </div>
                <div className="results-pagination">
                  <nav>
                    <ul className="pagination">
                      <li key={"9991"} className="page-item">
                        <button
                          className="prev page-link"
                          disabled={filters.page <= 1}
                          onClick={() =>
                            setFilters((res) => ({
                              ...res,
                              page: filters.page - 1,
                            }))
                          }
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>

                      {paginationElemnts(
                        data?.details,
                        filters.page,
                        data?.totalCount,
                        setFilters
                      )}
                      <li key={"9992"} className="page-item">
                        <button
                          className="next page-link"
                          disabled={
                            data?.details?.length < 10 ||
                            filters.page == Math.ceil(data?.totalCount / 10)
                          }
                          onClick={() =>
                            setFilters((res) => ({
                              ...res,
                              page: filters.page + 1,
                            }))
                          }
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
