/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Search from "../../assets/images/icon-search.svg";
import Arrow from "../../assets/images/pagination_arrow.svg";
import { AmlockStatus } from "./../../utils/Constants";

import {
  callRequest,
  AMLData,
  filters,
  paginationElemnts,
  updateAmlStatusPopUp,
  exportData,
  AMLDataList,
  callRequestFinancialId,
  FinanacialData,
} from "./hook/ScreenHelper";
import { useAppSelector } from "../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { Roles } from "../../utils/Constants";
import { TableRowPlaceholder } from "../../components/TableRowPlaceholder/TableRowPlaceholder";
import { hasRole } from "../../utils/Helpers";
import Moment from "react-moment";
import { Sidebar } from "primereact/sidebar";
import SidebarPlaceholder from "../../components/TableRowPlaceholder/SidebarPlaceholder";

export const AML: React.FC = () => {
  const { t } = useTranslation();
  const { loading } = useAppSelector((state) => state.settings);
  const [details, setDetails] = useState<AMLData>({});
  const [sidebarData, setSidebarData] = useState<any>({});

  const [filters, setFilters] = useState<filters>({
    page: 1,
    AMLStatus: AmlockStatus.ALL,
    includeRejectedLoans: false,
    loadAllRecords: false,
    nationalID: "",
    amlockStage: "",
  });

  const [data, setData] = useState<AMLDataList>(null);
  const [timer, setTimer] = useState(null);
  const [financialData, setFinancialData] = useState<FinanacialData>({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    callRequest(setData, filters);
  }, [filters]);

  const changePage = (currentPage: number) => {
    setFilters({ ...filters, page: currentPage });
  };

  /**
   * Returns true if the buttons should be shown for the item
   * @param item - AML item
   * @returns
   */

  const searchInputChanged = (value) => {
    setFilters({ ...filters, nationalID: value });

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      callRequest(setData, filters, { ...filters, nationalID: value });
    }, 500);

    setTimer(newTimer);
  };

  const UpdateStatus = (data, status) => {
    let payload = {
      financialDataId: data.financialDataId,
      status: status,
    };
    updateAmlStatusPopUp(payload, () => callRequest(setData, filters));
  };

  const formateText = (data) => {
    if (data === "APPROVED_BY_COMPLIANCE") return "Approved";
    else if (data === "NEED_COMPLIANCE_ACTION") return "Pending";
    else if (data === "REJECTED_BY_COMPLIANCE") return "Rejected";
    else if (data === "INVALID") return "Invalid";
  };

  const getColor = (data) => {
    let color = "";
    if (data === "APPROVED_BY_COMPLIANCE") color = "btn-success";
    else if (data === "NEED_COMPLIANCE_ACTION") color = "btn-warning";
    else if (data === "REJECTED_BY_COMPLIANCE") color = "btn-secondary";
    else if (data === "INVALID") color = "btn-danger px-4";
    return "btn rounded-pill py-0 px-3 my-0 text-white " + color;
  };

  useEffect(() => {
    setSidebarData({ ...details, ...financialData });
  }, [financialData]);

  const setDetailsFun = (data: AMLData) => {
    var inputString = data.searchList;
    var jsonString = inputString.replace(/\\/g, "");
    var searchListData = JSON.parse(jsonString);
    setDetails({ ...data, searchListData });
    callRequestFinancialId(setFinancialData, data.financialDataId);
  };

  const changeAmLockStatus = (data) => {
    setFilters({ ...filters, AMLStatus: data, page: 1 });
  };

  const validateText = (data) => {
    var value = "";
    data
      ? typeof data === "boolean"
        ? (value = data ? "YES" : "NO")
        : (value = data)
      : (value = "NA");

    return <p className={value !== "NA" && "text-primary"}>{value}</p>;
  };

  return (
    <>
      <div className="box_container">
        <div className="row g-3">
          <div className="col-md-12">
            <div className="row table-header justify-content-end">
              <div className="dropdown margin-end col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                <button
                  className="btn bg-gradient-dark mb-0 dropdown-toggle w-100"
                  data-bs-toggle="dropdown"
                  title="Payoutstatus"
                >
                  {t("inputs.payouts.status")} (
                  {t("inputs.aml.status." + filters.AMLStatus)})
                </button>
                <ul className="dropdown-menu dropdown-right-align">
                  <li key={AmlockStatus.ALL}>
                    <a
                      className="dropdown-item"
                      onClick={() => changeAmLockStatus(AmlockStatus.ALL)}
                    >
                      {t("inputs.aml.status.ALL")}
                    </a>
                  </li>
                  <li key={AmlockStatus.APPROVED_BY_COMPLIANCE}>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        changeAmLockStatus(AmlockStatus.APPROVED_BY_COMPLIANCE)
                      }
                    >
                      {t("inputs.aml.status.APPROVED_BY_COMPLIANCE")}
                    </a>
                  </li>
                  <li key={AmlockStatus.NEED_COMPLIANCE_ACTION}>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        changeAmLockStatus(AmlockStatus.NEED_COMPLIANCE_ACTION)
                      }
                    >
                      {t("inputs.aml.status.NEED_COMPLIANCE_ACTION")}
                    </a>
                  </li>
                  <li key={AmlockStatus.REJECTED_BY_COMPLIANCE}>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        changeAmLockStatus(AmlockStatus.REJECTED_BY_COMPLIANCE)
                      }
                    >
                      {t("inputs.aml.status.REJECTED_BY_COMPLIANCE")}
                    </a>
                  </li>
                  <li key={AmlockStatus.INVALID}>
                    <a
                      className="dropdown-item"
                      onClick={() => changeAmLockStatus(AmlockStatus.INVALID)}
                    >
                      {t("inputs.aml.status.INVALID")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row table-header">
              <div className="col-sm-12 col-md-11 mb-2">
                <div className="input-group search-input align-items-center">
                  <span className="input-group-text">
                    <img alt="" src={Search} />
                  </span>
                  <input
                    type="text"
                    className="form-select"
                    placeholder={t("inputs.aml.search_input_placeholder")}
                    onChange={(e) => searchInputChanged(e.target.value)}
                    value={filters.nationalID}
                  />
                  {filters.nationalID && (
                    <button
                      type="button"
                      className="btn-close ps mx-3"
                      aria-label="Close"
                      onClick={(e) => searchInputChanged("")}
                    ></button>
                  )}
                </div>
              </div>

              <div className="col-sm-12 col-md-1 mb-2">
                <div
                  className="d-flex align-items-center justify-content-end"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="First group"
                  >
                    {/* <button
                      className="btn bg-gradient-dark d-flex-center"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample"
                      aria-controls="offcanvasExample"
                      data-bs-placement="top"
                      title="Filter"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width="19"
                        height="19"
                      >
                        <path d="M3 4c0-.55.45-1 1-1h16c.55 0 1 .45 1 1 0 .22-.07.42-.2.58L14 13.51v6.75c0 .47-.35.9-.82.98-.58.11-1.15-.29-1.15-.89v-6.84L3.2 4.58A.996.996 0 013 4z" />
                      </svg>
                    </button> */}
                  </div>

                  <div
                    className="btn-group"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      className="btn bg-gradient-dark d-flex-center"
                      onClick={() => exportData(filters, t)}
                      type="button"
                      data-bs-toggle="offcanvas"
                      aria-controls="offcanvasExample"
                      data-bs-placement="top"
                      title="Download"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        fill="currentColor"
                        className="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row table-header">
              <div className="col-12 col-md-12 d-flex container-fluid align-items-center">
                <div className="input-group search-input align-items-center">
                  <span className="input-group-text">
                    <img alt="" src={Search} />
                  </span>
                  <input
                    type="text"
                    className="form-select"
                    placeholder={t("inputs.aml.search_input_placeholder")}
                    onChange={(e) => searchInputChanged(e.target.value)}
                    value={filters.nationalID}
                  />
                  {filters.nationalID && (
                    <button
                      type="button"
                      className="btn-close ps mx-3"
                      aria-label="Close"
                      onClick={(e) => searchInputChanged("")}
                    ></button>
                  )}
                </div>

                <div className="dropdown ms-2 margin-end">
                  <button
                    className="btn bg-gradient-dark mb-0 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    title="Payoutstatus"
                  >
                    {t("inputs.payouts.status")} (
                    {t("inputs.aml.status." + filters.AMLStatus)})
                  </button>
                  <ul className="dropdown-menu dropdown-right-align">
                    <li key={AmlockStatus.ALL}>
                      <a
                        className="dropdown-item"
                        onClick={() => changeAmLockStatus(AmlockStatus.ALL)}
                      >
                        {t("inputs.aml.status.ALL")}
                      </a>
                    </li>
                    <li key={AmlockStatus.APPROVED_BY_COMPLIANCE}>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          changeAmLockStatus(
                            AmlockStatus.APPROVED_BY_COMPLIANCE
                          )
                        }
                      >
                        {t("inputs.aml.status.APPROVED_BY_COMPLIANCE")}
                      </a>
                    </li>
                    <li key={AmlockStatus.NEED_COMPLIANCE_ACTION}>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          changeAmLockStatus(
                            AmlockStatus.NEED_COMPLIANCE_ACTION
                          )
                        }
                      >
                        {t("inputs.aml.status.NEED_COMPLIANCE_ACTION")}
                      </a>
                    </li>
                    <li key={AmlockStatus.REJECTED_BY_COMPLIANCE}>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          changeAmLockStatus(
                            AmlockStatus.REJECTED_BY_COMPLIANCE
                          )
                        }
                      >
                        {t("inputs.aml.status.REJECTED_BY_COMPLIANCE")}
                      </a>
                    </li>
                    <li key={AmlockStatus.INVALID}>
                      <a
                        className="dropdown-item"
                        onClick={() => changeAmLockStatus(AmlockStatus.INVALID)}
                      >
                        {t("inputs.aml.status.INVALID")}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="ms-2 d-flex">
                  <button
                    className="btn m-0 bg-gradient-dark text-white ms-1"
                    onClick={() => exportData(filters, t)}
                    type="button"
                    data-bs-toggle="offcanvas"
                    aria-controls="offcanvasExample"
                    data-bs-placement="top"
                    title="Download"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div> */}
          </div>

          <div className="col-md-12 ">
            <div className="table-responsive" style={{ minHeight: "25rem" }}>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ minWidth: 150 }} className="px-2" scope="col">
                      {t("inputs.aml.requestId")}
                    </th>
                    <th style={{ minWidth: 150 }} className="px-2" scope="col">
                      {t("inputs.aml.nationalId")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.aml.name")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.aml.nationality")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.aml.finantialDataId")}
                    </th>
                    {/* <th className="px-2" scope="col">
                      {t("inputs.aml.occupation")}
                    </th> */}
                    {/* <th style={{ minWidth: 180 }} className="px-2" scope="col">
                      {t("inputs.aml.employerName")}
                    </th> */}
                    <th style={{ minWidth: 150 }} className="px-2" scope="col">
                      {t("inputs.aml.partyType")}
                    </th>
                    <th style={{ minWidth: 240 }} className="px-2" scope="col">
                      {t("inputs.aml.stage")}
                    </th>
                    <th style={{ minWidth: 130 }} className="px-2" scope="col">
                      {t("inputs.aml.createdAt")}
                    </th>
                    <th
                      style={{ minWidth: 180 }}
                      scope="col"
                      className="table-actions-col px-2"
                    >
                      {t("inputs.actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <TableRowPlaceholder />}
                  {data?.AMLockResponse?.map((item, key) => (
                    <tr key={key}>
                      <td style={{ fontSize: 13 }}>{item.requestId}</td>
                      <td>{item?.govId}</td>
                      <td>{item?.customerName}</td>
                      <td>{item?.nationality}</td>
                      <td>{item?.financialDataId}</td>
                      {/* <td>{item?.occupation}</td>
                      <td>{item?.employerName}</td> */}
                      <td>{item?.partyType}</td>
                      <td>
                        <button className={getColor(item?.amlockStage)}>
                          {formateText(item?.amlockStage)}
                        </button>
                      </td>

                      <td style={{ minWidth: 140 }}>
                        <Moment format="YYYY-MM-DD HH:mm:ss" tz="Asia/Riyadh">
                          {item.createdAt}
                        </Moment>
                      </td>
                      <td className="table-actions-col">
                        {hasRole(Roles.compliance) && (
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary mx-3"
                              onClick={(e) => setDetailsFun(item)}
                            >
                              Details
                            </button>

                            {/* action */}

                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Button group with nested dropdown"
                            >
                              <div className="btn-group" role="group">
                                <button
                                  type="button"
                                  className={
                                    "btn btn-my-dropdown rounded bg-primary"
                                  }
                                  disabled={
                                    item?.amlockStage !==
                                    "NEED_COMPLIANCE_ACTION"
                                  }
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-three-dots-vertical text-white"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                  </svg>
                                </button>
                                <ul className="aml dropdown-menu shadow-lg">
                                  <li>
                                    <button
                                      onClick={() =>
                                        UpdateStatus(
                                          item,
                                          AmlockStatus.APPROVED
                                        )
                                      }
                                      className="dropdown-item text-primary fw-bold"
                                    >
                                      {t("inputs.aml.status.APPROVED")}
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() =>
                                        UpdateStatus(
                                          item,
                                          AmlockStatus.REJECTED
                                        )
                                      }
                                      className="dropdown-item text-primary fw-bold"
                                    >
                                      {t("inputs.aml.status.REJECTED")}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!loading && data?.AMLockResponse?.length === 0 && (
                <div className="text-center text-muted">No data available</div>
              )}
            </div>
            <div className="col-md-12 ">
              <div className="table-footer">
                <div className="results-count">
                  <p></p>
                </div>
                <div className="results-pagination">
                  <nav>
                    <ul className="pagination">
                      <li key={"9991"} className="page-item">
                        <button
                          className="prev page-link"
                          disabled={filters.page <= 1}
                          onClick={() => changePage(filters.page - 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                      {paginationElemnts(
                        data?.AMLockResponse,
                        filters.page,
                        data?.totalCount,
                        changePage
                      )}
                      <li key={"9992"} className="page-item">
                        <button
                          className="next page-link"
                          disabled={
                            data?.AMLockResponse?.length < 10 ||
                            data?.totalCount === filters.page
                          }
                          onClick={() => changePage(filters.page + 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Sidebar
        className="aml_sidebar"
        visible={Object.keys(sidebarData).length !== 0}
        position="right"
        onHide={() => setSidebarData({})}
      >
        {loading ? (
          <div className="filter offcanvas-filter">
            <SidebarPlaceholder />
            <SidebarPlaceholder />
            <SidebarPlaceholder />
            <SidebarPlaceholder />
          </div>
        ) : (
          <div className="filter offcanvas-filter">
            {/* details */}
            <div className="mb-3 sidebar_box">
              <h6 className="text-primary">{t("inputs.aml.details")}</h6>
              <hr className="w-100" />
              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.name")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.customerName)}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.dob")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.dateOfBirth)}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.nationalId")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.nationalId)}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.nationality")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.nationality)}
                </div>
              </div>
            </div>

            {/* search list */}
            <div className="mb-3 sidebar_box sidebar_table">
              <h6 className="text-primary position-sticky-table">
                {t("inputs.aml.searchList")}
              </h6>
              <hr className="w-100" />
              <div className="mb-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{t("inputs.aml.id")}</th>
                      <th style={{ minWidth: 110 }}>{t("inputs.aml.name")}</th>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.riskScore")}
                      </th>
                      <th style={{ minWidth: 110 }}>{t("inputs.aml.type")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sidebarData?.searchListData?.map((res) => (
                      <tr>
                        <td>{res.id}</td>
                        <td>{res.name}</td>
                        <td>{res.riskScore}</td>
                        <td>{res.type}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* financial Details */}
            <div className="mb-3 sidebar_box">
              <h6 className="text-primary">{t("inputs.aml.finanacialData")}</h6>
              <hr className="w-100" />

              <div className="row mb-4">
                <div className="col-6">
                  {t("inputs.aml.accommodationOwnership")}
                </div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.accommodationOwnership)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.accommodationType")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.accommodationType)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.consent")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.consent)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.decisionReason")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.decisionReason)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.dependents")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.dependents)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  {t("inputs.aml.dependentsHouseholdHelp")}
                </div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.dependentsHouseholdHelp)}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  {t("inputs.aml.dependentsPrivateSchool")}
                </div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.dependentsPrivateSchool)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  {t("inputs.aml.dependentsPublicSchool")}
                </div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.dependentsPublicSchool)}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.finalLoanAmount")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.finalLoanAmount)}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.homeOwnership")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.homeOwnership)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.iban")}</div>
                <div
                  className="col-6 fw-bold"
                  style={{ color: "#0000008a", wordBreak: "break-all" }}
                >
                  {validateText(sidebarData?.iban)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.ibanStatus")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.ibanStatus)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.interest")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.interest)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.interestRate")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.interestRate)}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.isAmlockVerified")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.isAmlockVerified)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.isBreadWinner")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.isBreadWinner)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  {t("inputs.aml.isCustomerResponsibleForPayment")}
                </div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.isCustomerResponsibleForPayment)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.isPoliticalPerson")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.isPoliticalPerson)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.isQuaraEmployee")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.isQuaraEmployee)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  {t("inputs.aml.isQuaraEmployeeRelative")}
                </div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.isQuaraEmployeeRelative)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.isRedfCustomer")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.isRedfCustomer)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.loanAmount")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.loanAmount)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.loanProduct")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.loanProduct)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.loanPurposeId")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.loanPurposeId)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.monthlyIncome")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.monthlyIncome)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  {t("inputs.aml.monthlyInstallmentAmount")}
                </div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.monthlyInstallmentAmount)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  {t("inputs.aml.nafaesSaleConfirmation")}
                </div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.nafaesSaleConfirmation)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.offerType")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.offerType)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.principle")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.principle)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.requestedAmount")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.requestedAmount)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  {t("inputs.aml.responsibleForPaymentPersonName")}
                </div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.responsibleForPaymentPersonName)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.sector")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.sector)}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">{t("inputs.aml.tenure")}</div>
                <div className="col-6 fw-bold" style={{ color: "#0000008a" }}>
                  {validateText(sidebarData?.tenure)}
                </div>
              </div>
            </div>

            {/* Address Information */}
            <div className="mb-3 sidebar_box sidebar_table">
              <h6 className="text-primary position-sticky-table">
                {t("inputs.aml.addressInfo")}
              </h6>
              <hr className="w-100" />
              <div className="mb-4 w-100">
                <table className="table w-100 overflow-auto">
                  <thead>
                    <tr>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.additionalNumber")}
                      </th>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.address1")}
                      </th>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.address2")}
                      </th>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.buildingNumber")}
                      </th>
                      <th style={{ minWidth: 110 }}>{t("inputs.aml.city")}</th>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.district")}
                      </th>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.isPrimaryAddress")}
                      </th>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.kCityCode")}
                      </th>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.kRegionCode")}
                      </th>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.postCode")}
                      </th>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.regionName")}
                      </th>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.street")}
                      </th>
                      <th style={{ minWidth: 110 }}>
                        {t("inputs.aml.unitNumber")}
                      </th>
                    </tr>
                  </thead>
                  {sidebarData?.addressInfo?.map((res) => (
                    <tbody>
                      <tr>
                        <td>{res.additionalNumber}</td>
                        <td>{res.address1}</td>
                        <td>{res.address2}</td>
                        <td>{res.buildingNumber}</td>
                        <td>{res.city}</td>
                        <td>{res.district}</td>
                        <td>{res.isPrimaryAddress}</td>
                        <td>{res.kCityCode}</td>
                        <td>{res.kRegionCode}</td>
                        <td>{res.postCode}</td>
                        <td>{res.regionName}</td>
                        <td>{res.street}</td>
                        <td>{res.unitNumber}</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        )}
      </Sidebar>
    </>
  );
};
