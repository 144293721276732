/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import Search from "../../assets/images/icon-search.svg";
import Arrow from "../../assets/images/pagination_arrow.svg";

import {
  paymentStatus,
  callRequest,
  PaymentData,
  Payment,
  filters,
  paginationElemnts,
  exportData,
} from "./hook/ScreenHelper";
import { useAppSelector } from "../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { TableRowPlaceholder } from "../../components/TableRowPlaceholder/TableRowPlaceholder";
import Filter from "../../assets/images/filter.svg";
import Moment from "react-moment";

export const Payments: React.FC = () => {
  const { t } = useTranslation();
  const { loading } = useAppSelector((state) => state.settings);
  const navigate = useNavigate();
  const locationObj = useLocation();
  const [operation, setOperation] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [inputs, setInputs] = useState<filters>({
    page,
  });
  const [filters, setFilters] = useState<filters>({
    page,
    loadAllDocuments: false,
    paymentStatus: "ALL",
  });
  // const [data, setData] = useState<Payment[]>(null);
  const [data, setData] = useState<PaymentData>(null);
  const [timer, setTimer] = useState(null);
  const [selectedFinance, setSelectedFinance] = useState<Payment[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    callRequest(setData, filters);
  }, [page]);

  useEffect(() => {
    setOperation(selectedFinance && selectedFinance.length > 0);
  }, [selectedFinance]);

  const changePage = (currentPage: number) => {
    setFilters({ ...filters, page: currentPage });
    setPage(currentPage);
  };

  const searchInputChanged = (value) => {
    setInputs({ ...inputs, search: value });
    setFilters({ ...filters, search: value });

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      callRequest(setData, filters, { ...filters, search: value });
    }, 500);

    setTimer(newTimer);
  };

  const changePaymentStatus = (data) => {
    if (data === filters.paymentStatus) {
      return;
    }
    let filterPayload = { ...filters, paymentStatus: data, page: 1 };
    setFilters(filterPayload);
    setPage(1);
    callRequest(setData, filterPayload);
  };

  return (
    <>
      <div className="box_container">
        <div className="row g-3">
          <div className="col-md-12">
            <div className="row table-header mb-3 text-end">
              <div className="dropdown margin-end">
                <button
                  className="btn bg-gradient-dark mb-0 dropdown-toggle col-12 col-sm-6 col-md-4 col-lg-2"
                  data-bs-toggle="dropdown"
                >
                  {t("inputs.payments.payment_status") + " "}
                  {filters.paymentStatus &&
                    "(" +
                      t("inputs.payments.status." + filters.paymentStatus) +
                      ")"}
                </button>
                <ul className="dropdown-menu dropdown-right-align">
                  {paymentStatus.map((type) => {
                    return (
                      <li key={type}>
                        <a
                          className="dropdown-item"
                          onClick={() => changePaymentStatus(type)}
                        >
                          {t("inputs.payments.status." + type)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="row table-header">
              <div className="col-sm-12 col-md-11 mb-2">
                <div className="input-group search-input align-items-center">
                  <span className="input-group-text">
                    <img alt="" src={Search} />
                  </span>
                  <input
                    type="text"
                    className="form-select"
                    placeholder={t("search_input_placeholder")}
                    onChange={(e) => searchInputChanged(e.target.value)}
                    value={inputs.search}
                  />
                  {inputs.search && (
                    <button
                      type="button"
                      className="btn-close ps mx-3"
                      aria-label="Close"
                      onClick={() => searchInputChanged("")}
                    ></button>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-1 mb-2">
                <div
                  className="d-flex align-items-center justify-content-end"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="First group"
                  >
                    {/* <button
                      className="btn bg-gradient-dark d-flex-center"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample"
                      aria-controls="offcanvasExample"
                      data-bs-placement="top"
                      title="Filter"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width="19"
                        height="19"
                      >
                        <path d="M3 4c0-.55.45-1 1-1h16c.55 0 1 .45 1 1 0 .22-.07.42-.2.58L14 13.51v6.75c0 .47-.35.9-.82.98-.58.11-1.15-.29-1.15-.89v-6.84L3.2 4.58A.996.996 0 013 4z" />
                      </svg>
                    </button> */}
                  </div>

                  <div
                    className="btn-group"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      className="btn bg-gradient-dark d-flex-center"
                      onClick={() => exportData(filters, t)}
                      type="button"
                      data-bs-toggle="offcanvas"
                      aria-controls="offcanvasExample"
                      data-bs-placement="top"
                      title="Download"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        fill="currentColor"
                        className="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row table-header">
              <div className="col-12 col-md-12 d-flex container-fluid align-items-center">
                <div className="input-group search-input align-items-center">
                  <span className="input-group-text">
                    <img alt="" src={Search} />
                  </span>
                  <input
                    type="text"
                    className="form-select"
                    placeholder={t("search_input_placeholder_payment")}
                    onChange={(e) => searchInputChanged(e.target.value)}
                    value={inputs.search}
                  />
                  {inputs.search && (
                    <button
                      type="button"
                      className="btn-close ps mx-3"
                      aria-label="Close"
                      onClick={() => searchInputChanged("")}
                    ></button>
                  )}
                </div>

                <div className="dropdown margin-end">
                  <button
                    className="btn bg-gradient-dark mb-0 dropdown-toggle mx-2"
                    data-bs-toggle="dropdown"
                  >
                    {t("inputs.payments.payment_status") + " "}
                    {filters.paymentStatus &&
                      "(" +
                        t("inputs.payments.status." + filters.paymentStatus) +
                        ")"}
                  </button>
                  <ul className="dropdown-menu dropdown-right-align">
                    {paymentStatus.map((type) => {
                      return (
                        <li key={type}>
                          <a
                            className="dropdown-item"
                            onClick={() => changePaymentStatus(type)}
                          >
                            {t("inputs.payments.status." + type)}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="ms-2 d-flex">
                  <button
                    className="btn m-0 bg-gradient-dark text-white ms-1"
                    onClick={() => exportData(filters, t)}
                    type="button"
                    data-bs-toggle="offcanvas"
                    aria-controls="offcanvasExample"
                    data-bs-placement="top"
                    title="Download"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div> */}
          </div>

          <div className="col-md-12 ">
            <div className="table-responsive" style={{ minHeight: "25rem" }}>
              <table className="table table-hover my-table">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: 100 }}>
                      {t("inputs.date")}
                    </th>
                    <th scope="col">{t("inputs.payments.nationalId")}</th>
                    <th scope="col">{t("inputs.payments.loanId")}</th>
                    <th scope="col">{t("inputs.payments.installmentId")}</th>
                    <th scope="col">{t("inputs.payments.type")}</th>
                    <th scope="col">{t("inputs.payments.currency")}</th>
                    <th scope="col">{t("inputs.payments.createdTime")}</th>
                    <th scope="col">{t("inputs.payments.amount")}</th>
                    <th scope="col">{t("inputs.status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <TableRowPlaceholder />}
                  {data?.payments?.map((item, key) => (
                    <tr key={key}>
                      <td style={{ minWidth: 140 }}>
                        <Moment format="YYYY-MM-DD HH:mm:ss" tz="Asia/Riyadh">
                          {item.createdAt}
                        </Moment>
                      </td>
                      <td>{item?.nationalId}</td>
                      <td>{item?.loanId}</td>
                      <td>{item?.installmentId}</td>
                      <td>{item?.type}</td>
                      <td>{item?.currency}</td>
                      <td style={{ minWidth: 140 }}>
                        <Moment format="YYYY-MM-DD HH:mm:ss" tz="Asia/Riyadh">
                          {item.createdAt}
                        </Moment>
                      </td>
                      <td>{item?.amount}</td>
                      <td>{item?.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!loading && data?.payments?.length === 0 && (
                <div className="text-center text-muted">No data available</div>
              )}
            </div>
            <div className="col-md-12 ">
              <div className="table-footer">
                <div className="results-count">
                  <p></p>
                </div>
                <div className="results-pagination">
                  <nav>
                    <ul className="pagination">
                      <li key={"9991"} className="page-item">
                        <button
                          className="prev page-link"
                          disabled={page <= 1}
                          onClick={() => changePage(page - 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                      {paginationElemnts(
                        data?.payments,
                        page,
                        data?.totalCount,
                        changePage
                      )}
                      <li key={"9992"} className="page-item">
                        <button
                          className="next page-link"
                          disabled={
                            data?.payments?.length < 10 ||
                            page === data?.totalCount / 10
                          }
                          onClick={() => changePage(page + 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* filter modal*/}
      <div
        className="offcanvas offcanvas-end offcanvas-filter"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            {t("filter") as string}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="mb-3">
            <label
              htmlFor="examplehtmlFormControlInput1"
              className="htmlForm-label"
            >
              {t("inputs.status") as string}
            </label>
            <select
              value={filters.paymentStatus}
              className="form-select"
              aria-label="Default select example"
              placeholder="Select status"
              onChange={(e) =>
                setFilters({
                  ...filters,
                  paymentStatus: e.target.value,
                  page: 1,
                })
              }
            >
              <option value="" disabled>
                {t("select_status").toString()}
              </option>
              <option value="SUCCESS">
                {t("inputs.payments.status.SUCCESS") as string}
              </option>
              <option value="REJECTED">
                {t("inputs.payments.status.REJECTED") as string}
              </option>
              <option value="PENDING">
                {t("inputs.payments.status.PENDING") as string}
              </option>
              <option value="INITIATED">
                {t("inputs.payments.status.INITIATED") as string}
              </option>
            </select>
          </div>
          <div className="mb-3">
            <label
              htmlFor="examplehtmlFormControlInput1"
              className="htmlForm-label"
            >
              {t("inputs.payments.kasalStatus") as string}
            </label>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="results">
            <strong> {data?.payments?.length + " " + t("results")} </strong>
          </div>
          <div className="main-buttons">
            <button
              data-bs-dismiss="offcanvas"
              type="button"
              className="btn btn-light"
              onClick={() => {
                setFilters({ paymentStatus: "", page: 1 });
                setPage(1);
                callRequest(setData, filters);
              }}
            >
              {t("clear").toString()}
            </button>
            <button
              onClick={() => {
                setPage(1);
                callRequest(setData, filters);
              }}
              type="button"
              className="btn btn-warning"
            >
              {t("apply").toString()}
            </button>
          </div>
        </div>
      </div>
      {/* end filter modal*/}
    </>
  );
};
