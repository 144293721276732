import React from "react";
import { useState } from "react";
import Logo from "../../assets/images/logo.svg";
import { localStorageHelper } from "../../hooks/localStorageHelper";
import { PROFILE_KEY, Roles, ROLES_KEY } from "../../utils/Constants";
import { hasAnyRole } from "../../utils/Helpers";
import { loginAPI } from "./hook/API";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const [uid, setUID] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState({ message: "" });

  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!uid || !uid.trim()) {
      setErrorMessage({ message: "Enter user id" });
      return;
    } else if (!password || !password.trim()) {
      setErrorMessage({ message: "Enter password" });
      return;
    }

    loginAPI({ email: uid, password: password }, (success) => {
      if (success) {
        //check whether user has the admin roles are not
        const allowedRoles = Object.values(Roles);
        const hasRole = hasAnyRole(allowedRoles);
        if (!hasRole) {
          removeUserData();
          setErrorMessage({
            message: "You are not authorized to access this application",
          });
          return;
        }
        setErrorMessage({ message: "" });
        window.location.reload();
      } else {
        removeUserData();
        setErrorMessage({
          message: "Invalid credentials",
        });
      }
    });
  };

  function removeUserData() {
    localStorageHelper.remove(PROFILE_KEY);
    localStorageHelper.remove(ROLES_KEY);
  }

  return (
    <section className="login">
      <img src={Logo} alt="" />

      <div className="box_container">
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            ID
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={(e) => setUID(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {errorMessage.message && (
          <div className="text text-danger">{errorMessage.message}</div>
        )}
        <Link to="/reset-password" className="text text-warning">
          {t("pages.resetPassword.resetpassword")}
        </Link>
        <button
          type="submit"
          className="btn btn-submit mt-2 submit-button"
          onClick={buttonHandler}
        >
          Submit
        </button>
      </div>
    </section>
  );
};
