/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import Search from "../../assets/images/icon-search.svg";
import Arrow from "../../assets/images/pagination_arrow.svg";

import {
  callRequest,
  callPayoutHistoryRequest,
  acceptPopUp,
  Payout,
  filters,
  paginationElemnts,
  showDetails,
  payoutProviderTypes,
  financeRoleOperations,
  creditRoleOperations,
  updatePayoutStatusPopUp,
  creditPayoutStatusList,
  financePayoutStatusList,
  exportData,
  creditRoleFinancePageOperations,
  PayoutData,
  PayoutHistoryFilter,
  PayoutHistoryData,
} from "./hook/ScreenHelper";
import { useAppSelector } from "../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ALL,
  PaymentProvider,
  PayoutPageType,
  PayoutStatus,
  Roles,
  RouterPath,
} from "../../utils/Constants";
import { TableRowPlaceholder } from "../../components/TableRowPlaceholder/TableRowPlaceholder";
import { hasRole } from "../../utils/Helpers";
import Moment from "react-moment";
import { Sidebar } from "primereact/sidebar";
import Filter from "../../assets/images/filter.svg";
import SidebarPlaceholder from "../../components/TableRowPlaceholder/SidebarPlaceholder";

export const Payouts: React.FC = () => {
  const { t } = useTranslation();
  const { loading } = useAppSelector((state) => state.settings);
  const navigate = useNavigate();
  const locationObj = useLocation();
  const [operation, setOperation] = useState<boolean>(false);
  const [payoutHistoryData, setPayoutHistoryData] =
    useState<PayoutHistoryData[]>(null);

  //Check the route and roles for finance
  const payoutMetaData = useMemo(() => {
    let payoutMetaobj = {
      isPayrollFinance: false,
      isPayrollCredit: false,
      payoutStatus: ALL,
      payoutStatusList: [],
      payoutPageType: PayoutPageType.FINANCE,
    };
    if (
      locationObj.pathname === RouterPath.PAYOUT_FINANCE_ROUTE &&
      hasRole(Roles.finance)
    ) {
      payoutMetaobj.isPayrollFinance = true;
      payoutMetaobj.payoutStatus = PayoutStatus.PENDING_FOR_FINANCE_APPROVAL;
      payoutMetaobj.payoutPageType = PayoutPageType.FINANCE;
      payoutMetaobj.payoutStatusList = [...financePayoutStatusList];
    } else if (
      locationObj.pathname === RouterPath.PAYOUT_CREDIT_ROUTE &&
      hasRole(Roles.credit)
    ) {
      payoutMetaobj.isPayrollCredit = true;
      payoutMetaobj.payoutStatus = PayoutStatus.PENDING_FOR_APPROVAL;
      payoutMetaobj.payoutPageType = PayoutPageType.CREDIT;
      payoutMetaobj.payoutStatusList = [...creditPayoutStatusList];
    }
    return payoutMetaobj;
  }, [locationObj.pathname]);

  const [page, setPage] = useState<number>(1);
  const [inputs, setInputs] = useState<filters>({
    page,
    includeRejectedLoans: false,
  });
  const [filters, setFilters] = useState<filters>({
    page,
    payoutProvider: ALL,
    payoutStatus: payoutMetaData.payoutStatus,
    payoutPageType: payoutMetaData.payoutPageType,
    includeRejectedLoans: false,
    loadAllRecords: false,
  });
  const [data, setData] = useState<PayoutData>(null);
  const [timer, setTimer] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFinance, setSelectedFinance] = useState<Payout[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    callRequest(setData, filters);
  }, [page]);

  const showCDCHistory = (item) => {
    const filter = {
      objectId: item.objectId ?? "",
      customerNameEn: item.customerName ?? "",
      customerNameAr: item.arabicName ?? "",
      compAppId: item.compAppId ?? "",
      nationalId: item.nationalId ?? "",
    };

    callPayoutHistoryRequest(setPayoutHistoryData, filter);
  };

  useEffect(() => {
    setOperation(selectedFinance.length > 0);
    console.log(selectedFinance.length > 0);
  }, [selectedFinance]);

  const changePage = (currentPage: number) => {
    setFilters({ ...filters, page: currentPage });
    setPage(currentPage);
  };

  /**
   * Returns true if the buttons should be shown for the item
   * @param item - payout item
   * @returns
   */
  const canShowButtons = (item) => {
    return (
      (payoutMetaData.isPayrollFinance &&
        item.status === PayoutStatus.PENDING_FOR_FINANCE_APPROVAL) ||
      (payoutMetaData.isPayrollCredit &&
        item.status === PayoutStatus.PENDING_FOR_APPROVAL)
    );
  };

  const searchInputChanged = (value) => {
    setInputs({ ...inputs, search: value });
    setFilters({ ...filters, search: value });

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      callRequest(setData, filters, { ...filters, search: value });
    }, 500);

    setTimer(newTimer);
  };

  const changePayoutProvider = (data) => {
    //If payout provider is same then return
    if (data === filters.payoutProvider) {
      return;
    }
    let filterPayload = { ...filters, payoutProvider: data, page: 1 };
    setFilters(filterPayload);
    callRequest(setData, filterPayload);
    setPage(1);
  };

  const changePayoutStatus = (data) => {
    //If payout status is same then return
    if (data === filters.payoutStatus) {
      return;
    }
    let filterPayload = { ...filters, payoutStatus: data, page: 1 };
    setFilters(filterPayload);
    callRequest(setData, filterPayload);
    setPage(1);
  };

  const updateRequest = (objectId: string, payoutStatus: string) => {
    let payoutObj = { objectId, payoutStatus };
    updatePayoutStatusPopUp(payoutObj, () => {
      //Remove the updated items from selected list.
      const newArray = selectedFinance.filter(
        (res) => res.objectId !== objectId
      );
      setSelectedFinance(newArray);
      callRequest(setData, filters);
    });
  };

  const getPayoutStatus = (status: string, data: Payout) => {
    if (payoutMetaData.isPayrollFinance) {
      if (data.payoutProvider === "ANB_PAY") {
        if (status === PayoutStatus.SUCCESSFUL)
          status = PayoutStatus.APPROVED_FROM_FINANCE;
        else status = PayoutStatus.REJECTED_FROM_FINANCE;
      } else {
        if (status === PayoutStatus.SUCCESSFUL)
          status = PayoutStatus.SUCCESSFUL;
        else status = PayoutStatus.REJECTED_FROM_FINANCE;
      }
    }
    return status;
  };

  const manualApproveStatus = (data) => {
    var status;
    if (data.payoutProvider === "ANB_PAY") {
      status = PayoutStatus.APPROVED_FROM_FINANCE;
    } else {
      status = PayoutStatus.SUCCESSFUL;
    }
    return status;
  };

  const validateText = (data) => {
    var value = "";
    value = data ? data : "NA";
    return <p className={value !== "NA" && "text-primary"}>{value}</p>;
  };

  const updateBulkRequest = (data: Payout[], payoutStatus: PayoutStatus) => {
    let payouts = data?.map((res) => ({
      objectId: res.objectId,
      payoutStatus: getPayoutStatus(payoutStatus, res),
      reasonOfRejection: "",
    }));
    const payload = {
      payouts: payouts.filter((res) => res !== null),
    };

    acceptPopUp(
      data,
      payload,
      payoutStatus,
      () => {
        //Remove the updated items from selected list.
        const newArray = selectedFinance.filter(
          (res) =>
            payload.payouts.findIndex(
              (pay) => pay.objectId === res.objectId
            ) === -1
        );
        setSelectedFinance(newArray);
        callRequest(setData, filters);
      },
      false
    );
  };

  const selectData = (e: boolean, key: number, item: Payout) => {
    if (e) {
      setSelectedFinance((prevItems) => {
        const prevItemsClone = [...prevItems];
        const index = prevItemsClone.findIndex(
          (res) => res.objectId === item.objectId
        );
        if (index === -1) {
          prevItemsClone.push(item);
        }
        return prevItemsClone;
      });
    } else {
      const newArray = selectedFinance.filter(
        (res) => res.objectId !== item.objectId
      );
      setSelectedFinance(newArray);
    }
  };

  const showButton = (item) =>
    canShowButtons(item) && selectedFinance.length === 0;

  const showStatus = (item) => {
    const result =
      item == "Failed"
        ? "text-white rounded-pill py-0 btn btn-danger"
        : item === "Successful"
        ? "text-white rounded-pill py-0 btn btn-success"
        : "rounded py-0 btn text-warning";

    return <span className={result}>{item}</span>;
  };

  return (
    <>
      <div className="box_container">
        <div className="row g-3">
          <div className="col-md-12">
            <div className="row table-header justify-content-end">
              <div className="dropdown margin-end col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                <button
                  className="btn bg-gradient-dark mb-0 dropdown-toggle w-100"
                  data-bs-toggle="dropdown"
                >
                  {t("common.payment_method")} (
                  {t("pages.payout.payment_methods." + filters.payoutProvider)})
                </button>
                <ul className="dropdown-menu dropdown-right-align">
                  {payoutProviderTypes.map((stage) => {
                    return (
                      <li key={stage}>
                        <a
                          className="dropdown-item"
                          onClick={() => changePayoutProvider(stage)}
                        >
                          {t("pages.payout.payment_methods." + stage)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* payout status */}
              <div className="dropdown margin-end col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                <button
                  className="btn bg-gradient-dark dropdown-toggle w-100"
                  data-bs-toggle="dropdown"
                  title="Payoutstatus"
                >
                  {t("inputs.payouts.status")} (
                  {t("pages.payout.status." + filters.payoutStatus)})
                </button>
                <ul className="dropdown-menu dropdown-right-align">
                  {payoutMetaData.payoutStatusList.map((payoutStatus) => {
                    return (
                      <li key={payoutStatus}>
                        <a
                          className="dropdown-item"
                          onClick={() => changePayoutStatus(payoutStatus)}
                        >
                          {t("pages.payout.status." + payoutStatus)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="row table-header">
              <div className="col-sm-12 col-md-10 mb-2">
                <div className="input-group search-input align-items-center">
                  <span className="input-group-text">
                    <img alt="" src={Search} />
                  </span>
                  <input
                    type="text"
                    className="form-select"
                    placeholder={t("search_input_placeholder")}
                    onChange={(e) => searchInputChanged(e.target.value)}
                    value={inputs.search}
                  />
                  {inputs.search && (
                    <button
                      type="button"
                      className="btn-close ps mx-3"
                      aria-label="Close"
                      onClick={() => searchInputChanged("")}
                    ></button>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-2 mb-2">
                <div
                  className="d-flex align-items-center justify-content-end"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group px-1"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      className="btn bg-gradient-dark d-flex-center"
                      type="button"
                      onClick={() => setShowFilters(true)}
                      title="Filter"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width="19"
                        height="19"
                      >
                        <path d="M3 4c0-.55.45-1 1-1h16c.55 0 1 .45 1 1 0 .22-.07.42-.2.58L14 13.51v6.75c0 .47-.35.9-.82.98-.58.11-1.15-.29-1.15-.89v-6.84L3.2 4.58A.996.996 0 013 4z" />
                      </svg>
                    </button>
                  </div>

                  <div
                    className="btn-group px-1"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      className="btn bg-gradient-dark d-flex-center"
                      onClick={() => exportData(filters, t)}
                      type="button"
                      data-bs-toggle="offcanvas"
                      aria-controls="offcanvasExample"
                      data-bs-placement="top"
                      title="Download"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        fill="currentColor"
                        className="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row table-header">
              <div className="col-12 col-md-12 d-flex container-fluid align-items-center">
                <div className="input-group search-input align-items-center">
                  <span className="input-group-text">
                    <img alt="" src={Search} />
                  </span>
                  <input
                    type="text"
                    className="form-select"
                    placeholder={t("search_input_placeholder")}
                    onChange={(e) => searchInputChanged(e.target.value)}
                    value={inputs.search}
                  />
                  {inputs.search && (
                    <button
                      type="button"
                      className="btn-close ps mx-3"
                      aria-label="Close"
                      onClick={() => searchInputChanged("")}
                    ></button>
                  )}
                </div>

                <div className="ms-2 d-flex">
                  <button
                    className="btn m-0 bg-gradient-dark"
                    type="button"
                    title="Filter"
                    onClick={() => setShowFilters(true)}
                  >
                    <img alt="" src={Filter} />
                  </button>
                  <button
                    className="btn m-0 bg-gradient-dark text-white ms-1"
                    onClick={() => exportData(filters, t)}
                    type="button"
                    data-bs-toggle="offcanvas"
                    aria-controls="offcanvasExample"
                    data-bs-placement="top"
                    title="Download"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div> */}
          </div>

          <div className="col-md-12 ">
            {operation &&
            data?.payouts?.length &&
            payoutMetaData.isPayrollCredit ? (
              <div className="container p-0 d-flex justify-content-end">
                <button
                  title="Approve the selected records"
                  onClick={() =>
                    updateBulkRequest(selectedFinance, PayoutStatus.APPROVED)
                  }
                  className="shadow btn btn-primary ms-3"
                >
                  {t(`inputs.payouts.statusList.APPROVED`)}
                </button>
                <button
                  title="Rejected the selected records"
                  onClick={() =>
                    updateBulkRequest(selectedFinance, PayoutStatus.REJECTED)
                  }
                  className="shadow btn btn-danger ms-3"
                >
                  {t(`inputs.payouts.statusList.REJECTED`)}
                </button>
              </div>
            ) : null}

            {operation &&
            data?.payouts?.length &&
            payoutMetaData.isPayrollFinance ? (
              <div className="container p-0 d-flex justify-content-end">
                <button
                  title="Approve the selected Records"
                  onClick={() =>
                    updateBulkRequest(selectedFinance, PayoutStatus.SUCCESSFUL)
                  }
                  className="shadow btn btn-primary ms-3"
                >
                  {t(`inputs.payouts.statusList.APPROVED`)}
                </button>
                <button
                  title="Reject the selected Records"
                  onClick={() =>
                    updateBulkRequest(selectedFinance, PayoutStatus.REJECTED)
                  }
                  className="shadow btn btn-danger ms-3"
                >
                  {t(`inputs.payouts.statusList.REJECTED`)}
                </button>
              </div>
            ) : null}

            <div className="table-responsive" style={{ minHeight: "25rem" }}>
              <table className="table table-hover my-table">
                <thead>
                  <tr>
                    <th scope="col" className="px-2">
                      {t("select")}
                    </th>
                    <th className="px-2" scope="col" style={{ width: 100 }}>
                      {t("inputs.date")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.payouts.customerName")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.payouts.bankName")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.payouts.iban")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.payouts.payoutProvider")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.payouts.compAppId")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.payouts.financialDataId")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.requestedAmount")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.payouts.transactionId")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.payouts.sequenceNumber")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.amount")}
                    </th>
                    <th className="px-2" scope="col">
                      {t("inputs.payouts.status")}
                    </th>
                    <th className="px-2" scope="col" style={{ minWidth: 180 }}>
                      {t("inputs.payouts.reasonOfFailure")}
                    </th>
                    {payoutMetaData.isPayrollFinance && (
                      <th className="px-2" scope="col">
                        {t("inputs.payouts.contract")}
                      </th>
                    )}
                    <th scope="col" className="table-actions-col px-2">
                      {t("inputs.actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <TableRowPlaceholder />}
                  {data?.payouts?.map((item, key) => (
                    <tr key={key}>
                      <td>
                        {canShowButtons(item) && (
                          <input
                            style={{ width: "1.3rem", height: "1.3rem" }}
                            onChange={(e) =>
                              selectData(e.target.checked, key, item)
                            }
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            checked={
                              selectedFinance.findIndex(
                                (res) => res.objectId === item.objectId
                              ) != -1
                            }
                          />
                        )}
                      </td>
                      <td style={{ minWidth: 140 }}>
                        <Moment format="YYYY-MM-DD HH:mm:ss" tz="Asia/Riyadh">
                          {item.createdAt}
                        </Moment>
                      </td>
                      <td>
                        <strong>{item.customerName}</strong>
                        {item.arabicName ? (
                          <p className="gray">
                            <strong>{item.arabicName}</strong>
                          </p>
                        ) : null}
                        <p className="gray">
                          <strong>{item.nationalId}</strong>
                        </p>
                      </td>
                      <td>{item.bankName}</td>
                      <td>{item.iban}</td>
                      <td>
                        {t(
                          "pages.payout.payment_methods." + item.payoutProvider
                        )}
                      </td>
                      <td>{item.compAppId}</td>
                      <td>{item.financialDataId}</td>
                      <td>{item.requestedAmount}</td>
                      <td>{item.transactionId}</td>
                      <td>{item.sequenceNumber}</td>
                      <td>{item.transferAmount}</td>
                      <td>
                        {showStatus(t("pages.payout.status." + item.status))}
                      </td>
                      <td>{item.reasonOfFailure}</td>
                      {payoutMetaData.isPayrollFinance && (
                        <td scope="col">
                          {item.status === "SUCCESSFUL" && item.compAppId}
                        </td>
                      )}
                      <td className="payout table-actions-col">
                        {/* For Ashraf Only */}

                        <div
                          className="btn-group w-100"
                          role="group"
                          aria-label="Button group with nested dropdown "
                        >
                          <div
                            className="payout_actions btn-group d-flex flex-column align-items-center w-100"
                            role="group"
                          >
                            {!hasRole(Roles.credit) && (
                              <button
                                type="button"
                                className="btn"
                                onClick={(e) => showDetails(t, item, navigate)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-card-checklist btn-light"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                  <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                                </svg>
                              </button>
                            )}

                            {item.status !== "FAILED" &&
                              item.status !== "SUCCESSFUL" && (
                                <button
                                  type="button"
                                  className={
                                    "btn btn-my-dropdown" +
                                    (showButton(item)
                                      ? " dropdown-toggle"
                                      : " text-dark") +
                                    (" " + item.status)
                                  }
                                  data-bs-toggle={
                                    showButton(item) ? "dropdown" : ""
                                  }
                                  aria-expanded="false"
                                >
                                  {t(
                                    "inputs.payouts.statusList." + item.status
                                  ).toString()}{" "}
                                </button>
                              )}
                            {item.status === "FAILED" && (
                              <button
                                onClick={(e) =>
                                  updateRequest(
                                    item.objectId,
                                    manualApproveStatus(item)
                                  )
                                }
                                className={"btn btn-primary rounded-pill py-0"}
                              >
                                {t(
                                  "inputs.payouts.statusList.APPROVE_NOW"
                                ).toString()}
                              </button>
                            )}
                            {canShowButtons(item) ? (
                              <ul className="dropdown-menu">
                                {payoutMetaData.isPayrollFinance &&
                                  item.payoutProvider ===
                                    PaymentProvider.HYPER_PAY &&
                                  item.status ===
                                    PayoutStatus.PENDING_FOR_FINANCE_APPROVAL &&
                                  financeRoleOperations.map(
                                    (operationStatus) => {
                                      return (
                                        <li
                                          key={
                                            key.toString() + "1" + item.objectId
                                          }
                                        >
                                          <button
                                            className="dropdown-item"
                                            onClick={(e) =>
                                              updateRequest(
                                                item.objectId,
                                                operationStatus
                                              )
                                            }
                                          >
                                            {t(
                                              `inputs.payouts.statusList.${operationStatus}`
                                            ).toString()}
                                          </button>
                                        </li>
                                      );
                                    }
                                  )}
                                {payoutMetaData.isPayrollFinance &&
                                  item.payoutProvider ===
                                    PaymentProvider.ANB_PAY &&
                                  item.status ===
                                    PayoutStatus.PENDING_FOR_FINANCE_APPROVAL &&
                                  creditRoleFinancePageOperations.map(
                                    (operationStatus) => {
                                      return (
                                        <li
                                          key={
                                            key.toString() + "1" + item.objectId
                                          }
                                        >
                                          <button
                                            className="dropdown-item"
                                            onClick={(e) =>
                                              updateRequest(
                                                item.objectId,
                                                operationStatus
                                              )
                                            }
                                          >
                                            {t(
                                              `inputs.payouts.statusList.${operationStatus}`
                                            ).toString()}
                                          </button>
                                        </li>
                                      );
                                    }
                                  )}
                                {payoutMetaData.isPayrollCredit &&
                                  item.status ===
                                    PayoutStatus.PENDING_FOR_APPROVAL &&
                                  creditRoleOperations.map(
                                    (operationStatus) => {
                                      return (
                                        <li
                                          key={
                                            key.toString() + "1" + item.objectId
                                          }
                                        >
                                          <button
                                            className="dropdown-item"
                                            onClick={(e) =>
                                              updateRequest(
                                                item.objectId,
                                                operationStatus
                                              )
                                            }
                                          >
                                            {t(
                                              `inputs.payouts.statusList.${operationStatus}`
                                            ).toString()}
                                          </button>
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            ) : null}
                            <button
                              onClick={() => showCDCHistory(item)}
                              className="btn btn-secondary rounded-pill py-0"
                            >
                              CDC History
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!loading && data?.payouts?.length === 0 && (
                <div className="text-center text-muted">No data available</div>
              )}
            </div>
            <div className="col-md-12 ">
              <div className="table-footer">
                <div className="results-count">
                  <p></p>
                </div>
                <div className="results-pagination">
                  <nav>
                    <ul className="pagination">
                      <li key={"9991"} className="page-item">
                        <button
                          className="prev page-link"
                          disabled={page <= 1}
                          onClick={() => changePage(page - 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                      {paginationElemnts(
                        data?.payouts,
                        page,
                        data?.totalCount,
                        changePage
                      )}
                      <li key={"9992"} className="page-item">
                        <button
                          className="next page-link"
                          disabled={
                            data?.payouts?.length < 10 ||
                            page === data?.totalCount / 10
                          }
                          onClick={() => changePage(page + 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        visible={showFilters}
        position="right"
        onHide={() => setShowFilters(false)}
      >
        <div className="filter offcanvas-filter">
          <div className="mb-2">
            <h5>{t("inputs.status")}</h5>
            <div className="d-flex">
              <input
                className="form-check"
                type="checkbox"
                id="includeRejectedLoansInput"
                checked={inputs.includeRejectedLoans}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    includeRejectedLoans: e.target.checked,
                  })
                }
              />
              <label htmlFor="includeRejectedLoansInput" className="ms-2">
                {t("inputs.includeRejectedLoans") as string}
              </label>
            </div>
          </div>

          <div className="offcanvas-footer">
            <div className="results">
              <strong> {data?.payouts?.length + " " + t("results")} </strong>
            </div>
            <div className="main-buttons">
              <button
                data-bs-dismiss="offcanvas"
                type="button"
                className="btn btn-light"
                onClick={async () => {
                  setInputs({ ...inputs, includeRejectedLoans: false });
                  const filterpayload = {
                    ...filters,
                    includeRejectedLoans: inputs.includeRejectedLoans,
                  };
                  setFilters(filterpayload);
                  callRequest(setData, filterpayload);
                }}
              >
                {t("clear").toString()}
              </button>
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => {
                  const filterpayload = {
                    ...filters,
                    includeRejectedLoans: inputs.includeRejectedLoans,
                  };
                  setFilters(filterpayload);
                  callRequest(setData, filterpayload);
                }}
              >
                {t("apply").toString()}
              </button>
            </div>
          </div>
        </div>
      </Sidebar>

      <Sidebar
        className="aml_sidebar"
        visible={payoutHistoryData?.length > 0}
        position="right"
        onHide={() => setPayoutHistoryData(null)}
      >
        {loading ? (
          <div className="filter offcanvas-filter">
            <SidebarPlaceholder />
            <SidebarPlaceholder />
            <SidebarPlaceholder />
            <SidebarPlaceholder />
          </div>
        ) : (
          <div className="filter offcanvas-filter">
            <h6
              className="h5 text-primary"
              style={{
                backgroundColor: "#fff",
                position: "absolute",
                top: "6%",
              }}
            >
              {t("inputs.payouts.payoutHistory")}
            </h6>
            <div className="container-fluid mt-2 px-0">
              {payoutHistoryData?.map((res) => (
                <div
                  className="mb-3 sidebar_box shadow-sm"
                  style={{ wordBreak: "break-all" }}
                >
                  <div className="row mb-4">
                    <div className="col-6">{t("inputs.payouts.objectId")}</div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.objectId)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">{t("inputs.payouts.uniqueId")}</div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.uniqueId)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      {t("inputs.payouts.transactionId")}
                    </div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.transactionId)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      {t("inputs.payouts.nationalId")}
                    </div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.nationalId)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      {t("inputs.payouts.reasonOfFailure")}
                    </div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.reasonOfFailure)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">{t("inputs.payouts.iban")}</div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.iban)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      {t("inputs.payouts.transferAmount")}
                    </div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.transferAmount)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">{t("inputs.payouts.bankBic")}</div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.bankBic)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      {t("inputs.payouts.payoutProvider")}
                    </div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.payoutProvider)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      {t("inputs.payouts.sequenceNumber")}
                    </div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.sequenceNumber)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      {t("inputs.payouts.transferOption")}
                    </div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.transferOption)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">{t("inputs.payouts.status")}</div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.status)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      {t("inputs.payouts.financialDataId")}
                    </div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.financialDataId)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      {t("inputs.payouts.customerName")}
                    </div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.customerName)}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      {t("inputs.payouts.arabicName")}
                    </div>
                    <div
                      className="col-6 fw-bold"
                      style={{ color: "#0000008a" }}
                    >
                      {validateText(res?.arabicName)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Sidebar>
    </>
  );
};
